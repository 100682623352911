// use .env instead. hostname is same, path is only differentiator between vms/twilio tokens
const urls = [
  'https://babel.higginsforpresident.net/vms-token',
];

const fetchFromUrl = (url) => fetch(url, { method: 'GET' })
  .then((response) => response.json())
  .then((response) => {
    console.log('VMS token fetched!', response);
    sessionStorage.setItem('vms-token', response.vmsToken);
    sessionStorage.setItem('lite-token', response.liteToken);
  })
  .catch((e) => {
    console.error('Unable to fetch valid VMS token.', e);
    sessionStorage.setItem('vms-token', null);
    sessionStorage.setItem('lite-token', null);
    throw e;
  });

export default () => new Promise((resolve) => {
  resolve();
  // fetchFromUrl(urls[0])
  //   .then(() => resolve())
  //   .catch(() => fetchFromUrl(urls[1]))
  //   .catch((e) => console.log('failed both times?', e))
  //   .then(() => resolve());
});
