const claims = (jwtString = '') => {
  let _claims;
  try {
    _claims = JSON.parse(atob(jwtString.split('.')[1]));
  } catch (e) {
    _claims = {
      participantSourceId: 12345,
    };
  }
  return _claims;
};

export default claims;
