/*
 * American Well Bento Sample Application
 *
 * Copyright © 2020 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

import 'babel-polyfill';

import { loader } from '@aw/video-loader';
import getBentoConfig from '@aw/public-config';

// import twilioBase from './twilio-base';
// import pexrtcBase from './pexrtc-base';
// import iframeBase from './iframe-base';
// import spinnerBase from './spinner-base';
import zoomBase from './zoom-base';

import fetchVmsToken from './fetch/vms-token';
import opts from './lib/url-kwargs';
import participantMenu from '@aw/video-plugin-participant-menu';
import './style.scss';

const fromUrl = opts(window.location.search);
console.warn('providing query param overrides', fromUrl);

// if we're in an RTL locale, set the attribute on the body element so we can mock-out what RTL will look like.
// the console requires a `[dir=rtl]` attribute on some parent element to let the additional styles kick in
const rtlLocales = ['iw-IL', 'iw', 'iw_IL'];
if (rtlLocales.includes(fromUrl.locale)) {
  document.body.setAttribute('dir', 'rtl');
}
window['aw-video-plugin-participant-menu'] = participantMenu;

const getContext = zoomBase;
let manifest;
let theConsole;

fetchVmsToken()
  .then(() => getBentoConfig({
    base: 'video',
    mix: {
      sidebar: {
        '@aw/video-plugin-chat': {}
      },
      toolbar: {
        '@aw/video-plugin-device-change': {},
        more: {
          '@aw/video-plugin-settings': {
            "$use":"zoom",
          },
          '@aw/video-plugin-twilio-screenshare': {
            "$use":"zoom",
          },
        }
      },
      '@aw/video-plugin-zoom': {},
      '@aw/video-plugin-vms-gateway': false,
    },
  }))
  .then((aManifest) => {
    delete aManifest['@aw/video-plugin-invite'];
    delete aManifest.sidebar['@aw/video-plugin-invite'];
    delete aManifest['@aw/video-plugin-participant-list'];
    delete aManifest.sidebar['@aw/video-plugin-participant-list'];
    console.warn('got a Manifest!', aManifest);
    manifest = aManifest;
  })
  .then(() => getContext(fromUrl))
  .then((context) => {
    console.warn('Got Context data', context);
    return loader(manifest, context, 'videoConsoleNode');
  })
  .then((aConsole) => {
    console.warn(aConsole);
    theConsole = aConsole;
    theConsole.start();
    window.theConsole = theConsole;
  })
  .catch((e) => {
    console.warn('Error running the startup', e, fromUrl);
  });

const mockErrors = [
  'PermissionDeniedError',
  // 'NotAllowedError',
  'NotFoundError',
  'SourceUnavailableError',
  // 'OverconstrainedError',
  // 'NotReadableError',
  // 'AbortError',
  // 'UserTimeout',
  'ScriptFetchError',
  'FooBarBaz',
].map((e) => {
  const x = new Error(`Mock ${e} invocation`);
  x.name = e;
  return x;
});

window.showMockError = (n) => {
  if (mockErrors[n]) {
    theConsole.emit('showPermissionWarning', mockErrors[n]);
  }
};
