export default (identity, roomName) => new Promise((resolve, reject) => {
  try {
    fetch('https://plutob.video-management.dev.amwell.systems/api/v1/zoom/auth/token', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        identity,
        roomName,
        "roleType":"HOST",
        "telemetry_tracking_id":"some_trace_id"
      })
    }).then(res => resolve(res.text()));
  } catch (e) {
    reject(e);
  }
});