// parse the [s]query[/s] string into a flattened object
const parse = (foo) => {
  const urlParams = new URLSearchParams(foo);
  return Array.from(urlParams.entries()).reduce((o, pair) => {
    const [k, v] = pair;
    o[k] = v;
    return o;
  }, {});
};

export default parse;
