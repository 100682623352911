/*
 * American Well Bento Sample Application
 *
 * Copyright © 2020 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

import 'babel-polyfill';

// random name is only for demo purpose.
// we should remove unique-names-generator after demo.
import { uniqueNamesGenerator, starWars } from 'unique-names-generator';

import fetchToken from './fetch/zoom-token';
import claims from './lib/claims';
// import zoom from '@aw/video-plugin-zoom';
// import selfView from '@aw/video-plugin-self-view';
// import thumbnailList from '@aw/video-plugin-thumbnail-list';
import DeviceChange from './sample_plugins/device_change';

window['aw-video-plugin-device-change'] = DeviceChange;

// window['aw-video-plugin-zoom'] = zoom; // @aw/video-plugin-zoom@alpha
// window['aw-video-plugin-self-view'] = selfView; // @aw/video-plugin-self-view@zoom
// window['aw-video-plugin-thumbnail-list'] = thumbnailList;

export default (kwArgs) => {
  // random name is only for sample purpose. pass ?identity=my+name to override
  const randomNameConfig = {
    dictionaries: [starWars],
  };

  const identity = kwArgs.identity || uniqueNamesGenerator(randomNameConfig);
  const roomName = kwArgs.room || 'demo_room';
  const role = kwArgs.role || 'PRACTITIONER';

  return fetchToken(identity, roomName)
    .then((token) => ({
      debug: true,

      participant: {
        id: claims(sessionStorage.getItem('lite-token') || '').participantSourceId,
        name: identity,
        role,
        token: sessionStorage.getItem('vms-token'),
        liteToken: sessionStorage.getItem('lite-token'),
        lastJoinTime: Date.now() - 20000,
        sourceId: Date.now(),
      },

      conference: {
        id: roomName,
        vendor: 'ZOOM',
        token,
      },

      tenant: {
        key: 'AMWL',
        isTranslationEnabled: true,
      },

      // expolode any kwArgs overrides, blindly passing to `console.config`
      ...kwArgs,

      plugins: {
        '@aw/video-plugin-main-speaker-view': {
          "$use": "zoom"
        },
        '@aw/video-plugin-spinner': false,
        '@aw/video-plugin-participant-list': false,
        '@aw/video-plugin-twilio': false,
        '@aw/video-plugin-self-view': {
          "$use": "zoom"
        },
        // vendor-adapter zoom
        '@aw/video-plugin-zoom': {
          "$use": "zoom",
          token,
        },
        '@aw/video-plugin-vms-gateway': false,
      },

      events: {
        // put any callback events you might want here
      },
    }));
};
