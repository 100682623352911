import { Plugin } from '@aw/video-core';

class DeviceChange extends Plugin {
  get title() { return 'device change?'; }

  get fullTitle() { return 'A Sample TrayThing'; }

  get defaults() {
    return {
      appendTo: 'bottomPaneCenterContainer',
      template: `<button data-aw-id="dchop" aria-label="Change Device" class="awl-control-button" data-aw-event="onclick: handleClick">
        <div class="awl-control-button-icon"></div>
        <div class="awl-control-button-label">Next Mic</div>
      </button>`,
    };
  }

  handleClick() {
    this.emit('switchMicrophone');
  }

  micDeviceChange(device) {
    this.log(`Mic is now: ${device.label}`);
  }

  cameraDeviceChange(device) {
    this.log(`Camera is now: ${device.label}`);
  }

  speakerDeviceChange(device) {
    this.log(`Speaker is now: ${device.label}`);
  }
}

export default DeviceChange;
